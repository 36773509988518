// Background colours
$backgroundColour: #ffffff;
$codeBackgroundColour: #fafafa;
$featureBackgroundColour: #f9fafa;
$accentColour: #05bf85;

// Text colours
$headingColour: #242e2b;
$bodyColour: #384743;
$linkColour: #03861a;
$hoverColour: #01390b;
$focusColour: #fa407a;
$captionColour: #a8adac;
$white: #ffffff;

// Typography
$bodytype: (
  font-family: 'Georgia, serif',
  regular: 400,
  bold: 700,
  italic: italic,
  cap-height: 0.75
);

$headingtype: (
  font-family: '"Merriweather", serif',
  regular: 400,
  bold: 700,
  cap-height: 0.75
);

$monospacetype: (
  font-family: 'Menlo, monospace',
  regular: 400,
  cap-height: 0.68
);

// Font import, if you're using a non-standard web font
@import url('https://fonts.googleapis.com/css?family=Merriweather:400,700');
